import { TLD_3NS, TLD_3NS_DOTTED } from '@app/constants/misc'
import { MINIMUM_DOT_ETH_CHARS, ParsedInputResult, split, validateName } from '@app/ensjs/src/utils'
import { Prettify } from '@app/types'
import { tryBeautify } from '@app/utils/beautify'

export type ValidationResult = Prettify<
  Partial<Omit<ParsedInputResult, 'normalised' | 'labelDataArray'>> & {
    name: string
    beautifiedName: string
    isNonASCII: boolean | undefined
    labelCount: number
    labelDataArray: ParsedInputResult['labelDataArray']
  }
>

const tryDecodeURIComponent = (input: string) => {
  try {
    return decodeURIComponent(input)
  } catch {
    return input
  }
}

export const parseInput = (input: string): ParsedInputResult => {
  let nameReference = input
  let isValid = false

  try {
    nameReference = validateName(input)

    isValid = true //nameReference.endsWith(TLD_3NS_DOTTED)
  } catch {}

  const normalisedName = isValid ? nameReference : undefined

  const labels = nameReference.split('.')
  const tld = labels[labels.length - 1]
  const isETH = tld === TLD_3NS
  const labelDataArray = split(nameReference)
  const isShort = (labelDataArray[0].output?.length || 0) < 1

  if (labels.length === 1) {
    return {
      type: 'label',
      normalised: normalisedName,
      isShort,
      isValid,
      is2LD: false,
      isETH,
      labelDataArray,
    }
  }

  const is2LD = labels.length === 2
  return {
    type: 'name',
    normalised: normalisedName,
    isShort: isETH && is2LD ? isShort : false,
    isValid,
    is2LD,
    isETH,
    labelDataArray,
  }
}

export const validate = (input: string) => {
  const decodedInput = tryDecodeURIComponent(input)
  const { normalised: name, ...parsedInput } = parseInput(decodedInput)
  const isNonASCII = parsedInput.labelDataArray.some((dataItem) => dataItem.type !== 'ASCII')
  const outputName = name || input

  return {
    ...parsedInput,
    name: outputName,
    beautifiedName: tryBeautify(outputName),
    isNonASCII,
    labelCount: parsedInput.labelDataArray.length,
  }
}

const defaultData = Object.freeze({
  name: '',
  beautifiedName: '',
  isNonASCII: undefined,
  labelCount: 0,
  type: undefined,
  isValid: undefined,
  isShort: undefined,
  is2LD: undefined,
  isETH: undefined,
  labelDataArray: [],
})

type UseValidateParameters = {
  input: string
  enabled?: boolean
}

const tryValidate = (input: string) => {
  if (!input) return defaultData
  try {
    return validate(input)
  } catch {
    return defaultData
  }
}

const map = new Map()

export const useValidate = ({ input }: UseValidateParameters): ValidationResult => {
  const mapValue = map.get(input)
  if (mapValue) return mapValue

  const value = tryValidate(input)
  map.set(input, value)
  return value
}
