/* eslint-disable @typescript-eslint/naming-convention */

const SUPPORT_LINKS = {
  homoglyphs: 'https://web3names.ai/search',
  namesAndSubnames: 'https://web3names.ai/search',
  managersAndOwners: 'https://web3names.ai/search',
  resolver: 'https://web3names.ai/search',
  fuses: 'https://web3names.ai/search',
  primaryName: 'https://web3names.ai/search',
  nameWrapper: 'https://web3names.ai/search',
  dnsNames: 'https://web3names.ai/search',
  gaslessDnssec: 'https://web3names.ai/search',
  'offchain-not-in-names': 'https://web3names.ai/search',
  owner: undefined,
  'owner-emancipated': undefined,
  'parent-owner': undefined,
  'dns-owner': undefined,
  manager: undefined,
  'profile-editor': undefined,
  'subname-manager': undefined,
  'eth-record': undefined,
  'grace-period': undefined,
  'contract-address': undefined,
  sendingNames: undefined,
}

type SupportTopic = keyof typeof SUPPORT_LINKS

export const getSupportLink = <T extends SupportTopic>(topic: T): (typeof SUPPORT_LINKS)[T] =>
  SUPPORT_LINKS[topic]
