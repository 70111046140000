import { QueryFunctionContext } from '@tanstack/react-query'
import { gql } from 'graphql-request'
import { namehash } from 'viem'

import { createSubgraphClient } from '@app/ensjs/src/subgraph'
import { useQueryOptions } from '@app/hooks/useQueryOptions'
import { ConfigWithEns, CreateQueryKey, QueryConfig } from '@app/types'
import { getIsCachedData } from '@app/utils/getIsCachedData'
import { prepareQueryOptions } from '@app/utils/prepareQueryOptions'
import { useQuery } from '@app/utils/query/useQuery'

type UseSubgraphMetadataInfoParameters = {
  name: string
}

export interface SubgraphMetadataInfoResult {
  metadataInfo?: {
    id: string
    currentVersion: string
    currentURL: string
  }
  domain: string
  title: string
  desc: string
  image: string
  systemPrompt: string
  intelligenceFiles: string[]
  websites: string[]
  prompts: string[]
}

type UseSubgraphMetadataInfoConfig = QueryConfig<SubgraphMetadataInfoResult, Error>

export type QueryKey<TParams extends UseSubgraphMetadataInfoParameters> = CreateQueryKey<
  TParams,
  'getSubgraphMetadataInfo',
  'graph'
>

export const getSubgraphMetadataInfoQueryFn =
  (config: ConfigWithEns) =>
  async <TParams extends UseSubgraphMetadataInfoParameters>({
    queryKey: [{ name }, chainId],
  }: QueryFunctionContext<QueryKey<TParams>>) => {
    const labels = name.split('.')

    const client = config.getClient({ chainId })
    const subgraphClient = createSubgraphClient({ client })

    const query = gql`
      query getMetadataInfo($id: String!) {
        metadataInfo(id: $id) {
          id
          currentVersion
          currentURL
        }
      }
    `
    const result = await subgraphClient.request<SubgraphMetadataInfoResult>(query, {
      id: BigInt(namehash(name)).toString(),
    })

    if (result?.metadataInfo) {
      const url = result.metadataInfo.currentURL.replace(
        'ipfs://',
        'https://gateway.pinata.cloud/ipfs/',
      )

      const data = await (await fetch(url)).json()

      return {
        ...result,
        ...data,
      }
    }
    return null
  }

export const useSubgraphMetadataInfo = <TParams extends UseSubgraphMetadataInfoParameters>(
  args = {} as TParams & UseSubgraphMetadataInfoConfig,
) => {
  const {
    // config
    enabled = true,
    gcTime,
    staleTime,
    scopeKey,
    ...params
  } = args

  const initialOptions = useQueryOptions({
    params,
    scopeKey,
    functionName: 'getSubgraphMetadataInfo',
    queryDependencyType: 'graph',
    queryFn: getSubgraphMetadataInfoQueryFn,
  })

  const preparedOptions = prepareQueryOptions({
    queryKey: initialOptions.queryKey,
    queryFn: initialOptions.queryFn,
    enabled,
    gcTime,
    staleTime,
  })

  const query = useQuery(preparedOptions)

  return {
    ...query,
    refetchIfEnabled: preparedOptions.enabled ? query.refetch : () => {},
    isCachedData: getIsCachedData(query),
  }
}
