import { useMemo } from 'react'
import { useChainId, useConfig } from 'wagmi'

export const useChain = () => {
  const config = useConfig()
  const chainId = useChainId()

  return useMemo(() => {
    return config.getClient({ chainId }).chain
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId])
}
