import { localhost, moonbaseAlpha } from 'wagmi/chains'

import type { Register } from '@app/local-contracts'
import { makeLocalhostChainWithEns } from '@app/utils/chains/makeLocalhostChainWithEns'

export const deploymentAddresses = JSON.parse(
  process.env.NEXT_PUBLIC_DEPLOYMENT_ADDRESSES || '{}',
) as Register['deploymentAddresses']

export const localhostWithEns = makeLocalhostChainWithEns<typeof localhost>(
  localhost,
  deploymentAddresses,
)

export const abitrumSepoliaWithEns = {
  ...moonbaseAlpha,

  contracts: {
    ensBaseRegistrarImplementation: {
      address: "0x017211Cc64F5FA5dbc32be0643Ea6a1328E1E656",
      //"BaseRegistrarImplementation": "0x017211Cc64F5FA5dbc32be0643Ea6a1328E1E656",
    },
    ensBulkRenewal: {
      address: "0x3CFc1a40640DcBba731623d569675d75590c9929",
      //"StaticBulkRenewal": "0x3CFc1a40640DcBba731623d569675d75590c9929",
    },
    ensDnsRegistrar: {
      address: "0x66090d411C96ad6a1F87aaD47E83c67a25A83EDc",
      //"DNSRegistrar": "0x66090d411C96ad6a1F87aaD47E83c67a25A83EDc",
    },
    
    ensDnssecImpl: {
      address: "0x7908d6033e374BDdcb8381f1A02a4f8E1Ad9c779",
      //  "DNSSECImpl": "0x7908d6033e374BDdcb8381f1A02a4f8E1Ad9c779",
    },
    ensEthRegistrarController: {
      address: "0x32481e19Bb109577789AcE5DfB56Eb93A4dDDaD0",
      // "ETHRegistrarController": "0x32481e19Bb109577789AcE5DfB56Eb93A4dDDaD0",
    },
    ensNameWrapper: {
      address: "0x7C04e8F4aC22ec4E756a7046B9fe170615D39544",
      // "NameWrapper": "0x7C04e8F4aC22ec4E756a7046B9fe170615D39544", 
    },
    ensPublicResolver: {
      address: "0xD18A9E62bb857315fbb995986d823bdf3Ef02904",
      //   "PublicResolver": "0xD18A9E62bb857315fbb995986d823bdf3Ef02904",
    },
    ensRegistry: {
      address: "0xf80f7dD96660041Caa2073cc4544AdCEEe6A70B6",
      //"ENSRegistry": "0xf80f7dD96660041Caa2073cc4544AdCEEe6A70B6",
    },
    ensReverseRegistrar: {
      address: "0x5478aa47Ebe1cbEB5384424976D2239937DA4d3e",
      //"ReverseRegistrar": "0x5478aa47Ebe1cbEB5384424976D2239937DA4d3e",
    },
    ensUniversalResolver: {
      address: "0x0399c168825C1236E115F66D8DF12C65fC70F217",
      //"UniversalResolver": "0x0399c168825C1236E115F66D8DF12C65fC70F217"
    },
    ensMetadataService: {
      address: "0x3bfC6C1936bfbf8D4Aa0f0b5b752f8b6C99366aC",
      //"MetadataService": "0x3bfC6C1936bfbf8D4Aa0f0b5b752f8b6C99366aC",
    },
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
    },
  },




  // contracts: {
  //   ensBaseRegistrarImplementation: {
  //     address: '0xF970f24F48F0A221D0A5eBBC4Be553DBEd783C43',
  //   },
  //   ensBulkRenewal: {
  //     address: '0x1fE8554A1c91b5053e410FCD9d50D91513Ca95bE',
  //   },
  //   ensDnsRegistrar: {
  //     address: '0x34f07684ce827F6E43597425b1351C351016E906',
  //   },
  //   ensDnssecImpl: {
  //     address: '0xea55eC2a0f119F35e22462d0BfDc660A4a5BB570',
  //   },
  //   ensEthRegistrarController: {
  //     address: '0xb566b0765eB8E49E76f1A64E46662670893ff007',
  //   },
  //   ensNameWrapper: {
  //     address: '0x9760D14AD17cC51F04e46bE655122486EeD89110',
  //   },
  //   ensPublicResolver: {
  //     address: '0x19694f6f2A0559a0F4C812f51FfcDAaCc0B76167',
  //   },
  //   ensRegistry: {
  //     address: '0x8de02e7Ef6b19efF65EEF2682b4eb03095528eaB',
  //   },
  //   ensReverseRegistrar: {
  //     address: '0xa9fE4B2D0B30B67AF5895d6550930B35F5F9b04c',
  //   },
  //   ensUniversalResolver: {
  //     address: '0x1a65fF10B2e54F38234b7dd09e19156B60E0F9C3',
  //   },
  //   ensMetadataService: {
  //     address: '0x6dE15e8B03E6cB152c90aCc596ddc1422C654A70',
  //   },
  //   multicall3: {
  //     address: '0xcA11bde05977b3631167028862bE2a173976CA11',
  //   },
  // },
  subgraphs: {
    ens: {
      url: "https://thegraph.com/studio/subgraph/3nstestarbitrumsepolia", // 16.11.2024
      //url: 'https://api.studio.thegraph.com/query/91249/3nstest/version/latest',
    },
  },
}

export const chainsWithEns = [abitrumSepoliaWithEns] as const

export const getSupportedChainById = (chainId: number | undefined) =>
  chainId ? chainsWithEns.find((c) => c.id === chainId) : undefined

export type SupportedChain = typeof abitrumSepoliaWithEns | typeof localhostWithEns
